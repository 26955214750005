import React from "react";

const GlobalContext = React.createContext<{
  virtualKeyboardEnabled: boolean;
  setVirtualKeyboardEnabled: (enabled: boolean) => void;
}>({
  virtualKeyboardEnabled: false,
  setVirtualKeyboardEnabled: () => {},
});
export default GlobalContext;
